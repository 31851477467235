/********************************************************
Variable SCSS for Variable Declaration
/********************************************************/
@import "_variable";

/********************************************************
Basic SCSS for Framework & Elements
/********************************************************/
@import "_basic";

/********************************************************
Sidebar SCSS for Sidebar Part
/********************************************************/
@import "_header";

/********************************************************
Components SCSS for All Components Fields
/********************************************************/
@import "_components";

/********************************************************
Animation SCSS for Animatate
/********************************************************/
@import "_animation";


.dashboard-wrapper {

  header {
    position: fixed;
    z-index: 999;
    width: 100%;
    background: #ffffff;
    top: 0;
    .bg-header {
      box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
      position: sticky;
    }
    .navbar-light {
      .navbar-nav .nav-link {
        color: $primary-text;
        &:hover {
          color: $primary-text;
        }
        &:focus, &:active {
          color: $primary-text;
        }
      }
      .navbar-collapse {
        .navbar-nav .nav-link {
          &:hover {
            color: $primary-text;
          }
          &:focus, &:active {
            color: $primary-text;
          }
        }
      }
    }
  }
  .sidebar-header {
    position: relative;
    height: 70px;
  }
  .sidebar-nav {
    .nav-item {
      height: 82px;
      display: flex;
      align-items: center;
      border: 1px solid #E0E0E0;
      margin-bottom: -1px;
      border-right: none;
      width: 100%;
      border-left: none;
      a {
        display: flex;
        align-content: center;
        align-items: center;
        padding: 19px 0;
        width: 100%;
        margin-left: 20px;
        color: #333333;
        -webkit-transition: all .35s ease;
        transition: all .35s ease;
        font-weight: 700;
        position: relative;
        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }
      }
      span {
        -webkit-transition: all .35s ease;
        transition: all .35s ease;
        background: #8e8b8b;
        box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
        border-radius: 100px;
        display: block;
        width: 42px;
        height: 42px;
        margin-right: 10px;
        margin-right: 10px;
        position: relative;
        .edit-nav {
          position: absolute;
          top: -18px;
          right: -28px;
        }
      }
      &.completed {
        background: transparent;
        margin-left: 0;
        border: none;
        span {
          background: linear-gradient(90deg,#4AD168 7.91%,#43C660 51.05%,#1AA73A 94.63%);
          box-shadow: 0px 10px 20px rgba(74,209,104,0.3);
        }
        .share-with-luci {
          .check-icon {
            position: absolute;
            top: -11px;
            right: -5px;
            width: 22px;
            height: 22px;
            background: #ffa156;
            border-radius: 25px;
            display: block;
          }
          .edit-icon {
            position: absolute;
            top: -11px;
            right: -5px;
            width: 22px;
            height: 22px;
            background: #ffa156;
            border-radius: 25px;
            display: none;
            padding: 2px;
          }

          &:hover {
            .check-icon {
              display: none;
            }
            .edit-icon {
              display: block;
            }
          }
        }
      }
      &.selected {
        z-index: 99;
        margin-left: 1px;
        background: #ffffff;
        a {
          color: #FF8976;
        }
        span {
          background: linear-gradient(90deg,#FF8C5A 7.91%,#FF9458 51.05%,#FFA655 94.63%);
          box-shadow: 0px 10px 20px rgba(255,142,90,0.3);
        }
      }
      &:first-child {
        span {
          padding: 10px 11px 11px 10px;
        }
      }
      &:last-child {
        border-bottom: none;
        span {
          padding: 8px 12px 8px 12px;
        }
      }
      &.selected {
        border-bottom: 1px solid #E0E0E0;
      }
    }
  }
  #sidebar.active {
    .sidebar-nav .nav-item a {
      font-size: 0;
      margin-left: 26px;
    }
  }
  a#sidebarCollapse {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .wrapper.profile-sidebar {
    margin-top: 90px;
  }
  .profile-sidebar {
    display: flex;
    align-items: stretch;
    width: 100%;
    #sidebar {
      min-width: 290px;
      max-width: 290px;
      transition: all 0.3s;
      margin-left: 0;
      background: #F6FCFF;
      border-right: none;
      height: auto;
      z-index: 9;
    }
    #content {
      width: 100%;
      //padding: 0 0 50px 0;
      transition: all 0.3s;
      padding: 30px 30px 0 30px;
      //min-height: 200px;
      //position: relative;
      border-left: 1px solid #E0E0E0;
    }
    #sidebar.active {
      min-width: 100px;
      max-width: 100px;
      text-align: center;
      a#sidebarCollapse {
        right: 27px;
        img {
          transform: rotate(180deg);
        }
      }
    }
    &.first-step-lg, &.second-step-lg {
      min-height: 100vh;
    }
  }
  .luci-card {
    border: none;
    z-index: 1;
    .luci-text {
      color: #333333;
      font-size: 16px;
      line-height: 24px;
      width: 50%;
    }
    .luci-title {
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      color: #222222;
      margin-top: 10px;
      margin-bottom: 15px;
      text-align: left;
      width: 600px;
      max-width: 100%;
      .line {
        display: inline-block;
      }
    }
    .lock-icon {
      padding-bottom: 5px;
      margin-right: 10px;
    }
  }
  .first-step {
    position: relative;
    &:before {
      content: "";
      position: fixed;
      background-image: url(../../assets/images/dashboard/step-bg.svg);
      background-repeat: no-repeat;
      height: 500px;
      width: 500px;
      bottom: 0;
      left: auto;
      right: 0;
      z-index: 9;
    }
  }
  .help-icon {
    margin-top: -3px;
  }
  .modal-popover {
    position: relative;
    display: inline-block;
    .tooltip-luci {
      top: 25px;
      left: -25px;
      right: 0;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      background: #fff;
      width: 350px;
      border-radius: 0;
      text-align: left;
      padding: 5px 10px;
      font-size: 14px;
      z-index: 9;
      -webkit-transition: all 0.7s ease;
      transition: all 0.7s ease;
      box-shadow: 0 1px 6px rgba(57,73,76,0.35);
      i {
        content: "";
        position: absolute;
        top: -6px;
        left: 33px;
        border: 8px solid #ffffff;
        border-color: transparent transparent #fff #fff;
        transform: translateX(-50%) rotate(135deg);
        box-shadow: -2px 2px 3px rgba(57,73,76,0.1);
        right: auto;
      }
    }
    &:hover {
      .tooltip-luci {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .margin-0 {
    margin: 0px auto;
  }
  .plaid-hide-button{
    display: none;
  }
  .luci-sub-title {
    font-size: 20px;
    color: #222222;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .reLoginRequiredInstitutesContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;

    .reLoginRequiredInstitutesCard {
      display: flex;
      flex-direction: row;
      gap: 48px;
      justify-content: space-between;

      .instituteNameBox {
        display: flex;
        flex-direction: row;
        gap: 4px;

        .instituteWarningBox {
          display: flex;
          flex-direction: column;

          .warning-icon {
            width: 17px;
            height: 17px;
          }
        }

        .instituteBox {
          display: flex;
          flex-direction: column;

          .instituteName {
            font-size: 19px;
            color: #222222;
            font-weight: 600;
            line-height: 0.8;
          }

          .warning {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .luci-btn {
        height: 35px;
        padding: 0 4px;
        min-width: 145px;
      }

      .gray-color-btn {
        background: #838383;
        color: white;
      }
    }
  }
  .bank-area {
    width: 590px;
    margin-bottom: 20px;
    .card-header {
      padding: 0;
      position: relative;
      .btn-link {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 15px 10px 15px 50px;
        color: #222222;
        background: #ffffff;
        text-decoration: none;
        .card-name {
          color: #222222;
          font-size: 14px;
          font-weight: 600;
          display: block;
          text-decoration: none;
          text-align: left;
          margin-left: 15px;
          span {
            display: block;
            font-size: 12px;
            font-weight: 500;
          }
        }
        .card-img{
          width: 50px;
        }
        .bank-arrow {
          position: absolute;
          left: 20px;
        }
        .card-enable {
          margin-left: auto;
          margin-right: 10px;
          font-size: 14px;
          font-weight: 600;
          color: #222222;
        }
        &[aria-expanded="false"] {
          .bank-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
    .card-sub-data {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0;
      color: #222222;
      background: #F9F9F9;
      text-decoration: none;
      position: relative;
      .card-name {
        font-size: 12px;
        font-weight: 600;
        margin-left: 0;
        span {
          display: block;
          font-size: 14px;
          font-weight: 300;
          .mask {
            display: inline-block;
          }
          .message {
            display: inline-block;
            margin-left: 10px;
            font-size: 12px;
            font-weight: 600;
            color: red;
          }
        }
      }
      .luci-btn{
        margin-left: auto;
        margin-right: 16px;
        height: 36px;
        padding: 7px 34px;
        min-width: 125px;
      }
      .card-img {
        width: 40px !important;
        display: unset !important;
        margin-right: 10px;
      }
      .toggle {
        position: absolute;
        right: 15px;
        top: 10px;
      }
      .card-enable {
        font-size: 18px;
        font-weight: 600;
        margin-left: auto;
        margin-right: 120px;
      }
    }
    .bg-gray {
      background: #F9F9F9;
    }
    .accordion>.card>.card-header {
      margin-bottom: 0;
    }
    .accordion>.card:not(:last-of-type) {
      border-bottom: 1px solid rgba(0,0,0,.125);
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 22px;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #c3c1c1;
      -webkit-transition: .4s;
      transition: .4s;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 3px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    input:checked + .slider {
      background-color: #67D68E;
    }
    input:checked + .slider:before {
      left: -4px;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
  .btn.focus, .btn:focus {
    box-shadow: none;
  }
  .disable-card {
    .card-name, .card-enable {
      color: #9D9D9D;
    }
  }
  .second-step {
    width: 100%;
    .luci-title {
      width: 70%;
    }
  }
  .spending-title {
    .date-format {
      text-decoration: underline;
    }
  }
  .tab-card {
    margin: 30px 0 15px 0;
    .tab-item-title {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      border-bottom: 1px solid #dfdfdf;
      .tab-item {
        padding-bottom: 10px;
        text-align: center;
        flex-grow: 1;
        margin: 0 3px;
        border-bottom: 5px solid #ffffff;
        a {
          color: #222222;
          padding: 5px 15px;
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
        }
        &.active {
          border-bottom: 5px solid #FF8D5B;
        }
        .arrow-tab {
          width: 12px;
          transform: rotate(180deg);
        }
      }
      .modal-popover .tooltip-luci {
        right: 0;
        left: auto;
        i {
          top: -8px;
          right: 54px;
          left: auto;
        }
      }
    }
  }
  .tab-card-data {
    background: #FFFFFF;
    border: 1px solid #DDE1ED;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;
    position: relative;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    .top-pick {
      margin: -30px 0px 15px -40.5px;
      span {
        border: 1px solid #43aade;
        background: #43aade;
        padding: 2px 10px;
        border-radius: 0 5px 5px 0px;
        color: white;
      }
    }
    .card-first {
      display: flex;
      justify-content: start;
      min-height: 130px;

      .t-card-img {
        flex: 1;
      }
      .t-card-name {
        flex: 10;
      }
      .t-card-action {
        flex: 1
      }
    }
    .card-image {
      max-width: 175px;
      min-width: 175px;
    }
    .t-card-name {
      margin: 0 30px;
      h4 {
        font-size: 32px;
        font-weight: 400;
      }
    }
    .total-rewards {
      border-top: none;
      border-bottom: 1px solid #efefef;
      margin: 0;
      background: white;
      border-radius: 25px 25px 0 0;
      padding: 10px 20px;
      &.no-top-padding {
        padding-top: 0 !important;
      }
      .is-top-padding {
        //flex-direction: column;
        padding-top: 13px;
      }
      .value {
        color: #FDA586;
      }
    }
    
    .card-points {
      border: 1px solid #dfdfdf;
      border-radius: 15px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 30px 0;
      background: #FFF9F3;
      overflow: hidden;
      max-width: 950px;
      &.not-included-intro-bonus {
        max-width: 700px;
      }
      .card-r {
      }
      .font-big {
        font-size: 26px;
        font-weight: 600;
        margin-right: 5px;
      }
      .rft {
        display: block;
      }
      .font-small {
        font-size: 18px;
        margin-right: 5px;
      }
      .a-center {
        align-items: center;
      }
      > div {
        padding: 15px 0;
      }
    }
    .card-reward {
      border-left: 1px solid #dfdfdf;
      background: #ffffff;
      padding: 18px 50px 15px 15px !important;
      .rft {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .xs-view-card-reward {
      border-left: 1px solid #dfdfdf;
      background: #ffffff;
      padding: 18px 50px 15px 60px !important;
      .rft {
        font-size: 18px;
        font-weight: 700;
      }
    }
    &:has(.see-more-expanded) {
      min-height: 1000px;
    }
    .expanded-details {
      opacity: 1;
      transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
      margin-top: 20px;
      &.fading-in {
        opacity: 0.1;
      }
    }
  }
  .card-features {
    .card-f-area {
      justify-content: space-between;
      display: flex;
      .card-right {
        margin-right: auto;
        width: 50%;
        .cf-icons {
          display: flex;
          flex-wrap: wrap;
        }
        .cfi-item {
          display: flex;
          align-items: center;
          justify-content: start;
          width: 50%;
          margin-top: 15px;
          font-weight: normal;
          text-align: left;
          line-height: 15px;
          img:not(.gray-help) {
            margin-right: 15px;
          }
        }
      }
    }
    .card-left {
      width: 50%;
      border-left: 1px solid rgba(0,0,0,0.06);
      padding-left: 50px;
      .cards {
        display: flex;
        flex-direction: column;
      }
      .cl-item {
        margin-bottom: 20px;
        .cl-t {
          font-weight: 600;
          font-size: 17px;
          display: block;
        }
      }
      .cl-info {
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
    }
    .qt-item {
      margin-bottom: 12px;
      text-align: left;
      display: flex;
      flex-direction: column;
      .qt-t {
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
      }
      .qt-info {
        font-size: 14px;
        display: flex;
      }
    }
    
    .popup-card-left {
      width: 50% !important;
      flex-wrap: wrap !important;
      border-left: none;
      padding-left: 0;
    }
    .popup-card-right {
      border-left: 1px solid #dfdfdf;
    }
    .mob-card-left {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 20px;
    }
    .cr-title {
      font-size: 18px;
      font-weight: 600;
    }
  }
  img.black-arrow {
    position: absolute;
    left: -48px;
    top: 206px;
    bottom: 0;
    cursor: pointer;
    &.right {
      position: absolute;
      left: auto;
      top: 186px;
      bottom: 0;
      right: -50px;
      transform: rotate(180deg);
      &.is-disabled {
        opacity: 0.1;
      }
    }
    &.is-disabled {
      opacity: 0.1;
    }
  }
  img.gray-help {
    height: 20px;
    opacity: 0.3;
    position: relative;
    bottom: 0.5px;
    cursor: pointer;
  }
  .data-graph {
    margin: 25px 0;
    .dg-title {
      font-size: 17px;
      font-weight: 600;
    }
    @media (max-width:567px) {
      margin: 0px 0;
      .dg-title {
        margin-bottom: 0;
      }
    }
    .dg-dates {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      align-items: center;
      .dg-date {
        font-size: 18px;
        font-weight: 500;
        //margin-left: 45px;
        min-width: 300px;
        text-align: center;
      }
      .btn-tab {
        display: flex;
        a {
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
          &:first-child{
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
          }
          &:last-child{
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }
        .dark-link {
          background: #333333;
          padding: 10px 30px;
          color: #ffffff;
        }
        .light-link {
          border: 1px solid #E5E9F1;
          background: #ffffff;
          padding: 10px 30px;
          color: #333333;
        }
      }
    }
    .graph-info {
      display: flex;
      width: 80%;
      position: relative;
      justify-content: space-between;
      margin-top: 5px;
      flex-wrap: wrap;

      .gr-item {
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: space-between;
        //align-items: center;
        margin-bottom: 8px;
        position: relative;
        max-height: 35px;
        font-size: 14px;
        &.title {
          color: #696974;
          margin-bottom: 5px;
          .sp-l {
            width: 70px;
            display: block;
          }
          .sp-r {
            width: 80px;
          }
        }
        &:first-child{
          &:before{
            content:none;
          }
        }
      }
      .graph-right {
        flex: 1;
        position: relative;
        margin-right: 80px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 40px;

        .gr-spending {
          //width: auto;
          width: 110px;
          text-align: right;
          color: #696974;
        }
        .gr-reward {
          color: #FF8C5A;
          width: 110px;
          text-align: right;
        }
        .gr-name {
          width: 260px;
          max-width: 260px;
          display: flex;
          align-items: center;
          padding-right: 4px;
          svg path {
            fill: red;
          }
          .cat-name {
            min-width: 205px;
            text-align: left;
            line-height: 16px;
            display: flex;
            align-items: center;
            padding-left: 2px;
            margin-right: auto;
          }
        }
        .gr-item img:not(.gray-help) {
          width: 25px;
          margin-right: 5px;
          padding: 5px;
          border-radius: 5px;
        }
      }
      .graph-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .pick-text {
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    margin: 20px 0 15px 0;
  }
  .card-filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    align-items: center;
    .feature-filter {
      margin-left: 10px;
      margin-right: 5px;
    }
    .filter {
      display: flex;
      .ant-btn:hover {
        color: #ff9459;
        background: #fff;
        border-color: #ff9459;
      }
      .ant-btn:focus {
        color: #ff9459;
        background: #fff;
        border-color: #ff9459;
      }
      .arrow-tab-more {
        transform: rotate(180deg);
      }
    }
    .free-text {
      max-width: 515px;
      //margin-bottom: 10px;
    }
    button {
      border-radius: 25px;
    }
  }
  .year-projection {
    display: flex;
    margin-bottom: 12px;
    > div {
      padding: 4px 10px 3px 10px;
      border: 1px solid #dfdfdf;
      cursor: pointer;
      text-align: center;
    }
    .first-year {
      border-radius: 15px 0 0 15px;
      &.selected {
        border: 1px solid $link-text;
        color: $link-text;
      }
      border-right: none;
    }
    .second-year {
      &.selected {
        border: 1px solid $link-text;
        color: $link-text;
      }
    }
    .last-year {
      border-radius: 0 15px 15px 0;
      &.selected {
        border: 1px solid $link-text;
        color: $link-text;
      }
      border-left: none;
    }
  }
  .spending-tab {
    justify-content: center;
    min-height: 43px;
    .first-year, .second-year, .last-year {
      min-width: 120px;
      font-weight: 600;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.selected {
        background: linear-gradient(to right, #FF8C5A, #FF9458, #FFA655);
        color: $white-color;
      }
      @media (max-width: 400px) {
        min-width: 100px;
      }
    }
    @media (max-width: 472px) {
      .first-year-category {
        line-height: 40px;
        align-items: center;
      }
    }
  }
  .spending-behavior-tab {
    .first-year, .second-year, .last-year {
      color: #222222;

      &.selected {
        background: #343C4B;
        border: 1px solid #343C4B;
        color: white;
      }
    }
  }
  .see-more {
    text-align: center;
    margin-top: 12px;
    a {
      &:hover {
        cursor: pointer;
        opacity: 0.8;
        text-decoration: underline;
      }
    }
    .arrow-tab-more {
      transform: rotate(180deg);
    }
    .card-body {
      border: none;
    }
    .card-dtl {
      .card-dtl-item {
        display: flex;
        align-items: start;
        text-align: left;
        justify-content: flex-start;
        width: 100%;
        p {
          margin-left: 15px;
          margin-right: 15px;
        }
        img{
          margin-top: 4px;
        }
        width: 100%;
        span {
          margin-left: 10px;
          margin-right: 0;
          margin-bottom: 12px;
          line-height: 16px;
        }
      }
      .cr-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
    a[aria-expanded="true"] {
      .arrow-tab-more {
        transform: rotate(0deg);
      }
    }
    a[aria-expanded="false"] {
      .arrow-tab-more {
        transform: rotate(180deg);
      }
    }
  }
  .dot-slider {
    margin: 0px auto;
    text-align: center;
    padding: 30px 0 0 0;
    img {
      margin: 0 3px;
    }
  }
  .page-number {
    margin: 0px auto;
    text-align: center;
    padding: 10px 0;
    font-weight: 600;
  }
  .view-card {
    margin: 20px auto;
    text-align: center;
    padding: 10px 0;

    .va-title {
      display: block;
      width: 120px;
      margin: 0 auto;
      text-align: center;
      font-weight: 700;
      color: #333333;
      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
      &[aria-expanded="true"] {
        .arrow-tab-more {
          transform: rotate(0deg);
        }
      }
      &[aria-expanded="false"] {
        .arrow-tab-more {
          transform: rotate(180deg);
        }
      }
    }
    .arrow-tab-more {
      text-align: center;
      transform: rotate(180deg);
    }
  }
  .table-card {
    margin: 60px 0 10px 0;
    .header-card {
      display: flex;
      align-items: center;
      .right-filter {
        margin-left: 10px;
        .ant-select-selector{
          border-radius: 50px;
        }
      }
      .left-filter-label {
        margin-left: 30px;
      }
      .left-filter {
        margin-left: 10px;
        .ant-select-selector{
          border-radius: 50px;
        }
      }
      .right-filter-label {
        margin-left: auto;
      }

      .custom-dropdown-box {
        min-width: 170px;
        .custom-dropdown {
          text-align: left;
        }
      }
    }
    .all-card {
      font-size: 24px;
      color: #171725;
      font-weight: 700;
      width: 100%;
      text-align: left;
      display: block;
    }
    .projection-filter-menu {
      display: flex;
      align-items: end;
      justify-content: space-between;

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .table-card-data {
    .table-card {
      border-collapse: collapse;
      width: 100%;
      margin-top: 8px;
      text-align: center;
      th {
        text-align: inherit;
        padding: 15px 25px 15px 12px;
        background: #F1F1F5;
        font-weight: 600;
        border: 1px solid #F1F1F5;
        word-break: keep-all;
        position: relative;
      }
      td {
        padding: 5px;
        border: 1px solid #EEEEEE;
        .feature-tab {
          text-align: left;
          align-items: center;
          margin-bottom: 5px;

          img {
            margin-right: 5px;
          }
        }
        .blue-txt {
          color: #0364AE;
          font-size: 14px;
        }
        .luci-btn {
          border-radius: 25px;
          padding: 0 18px;
          min-width: 105px;
          font-size: 13px;
          height: auto;
          line-height: 10px;
        }
        &.first-c {
          padding: 5px 20px;
          word-break: break-word;
          text-align: left;
          min-width: 150px;

          .card-tbl-img {
            margin-bottom: 5px;
            max-width: 80px;
            min-width: 80px;
          }
          span.card-nm {
            font-weight: 500;
          }
        }
      }
      .w-nowarp {
        white-space: nowrap;
      }
      .t-left {
        text-align: left;
        padding-left: 10px;
      }
      .d-grid {
        display: grid;
        padding: 10px 20px 0 20px;
      }
      .primary-bg {
        background-color: rgba(255,142,90,0.2);
      }
    }
  }
  .table td, .table th {
    vertical-align: middle;
  }
  .mobile-filter {
    display: none;
  }
  .mob-card-table {
    display: none;

    .total-rewards {
      border-bottom: 1px solid #efefef;
      border-top: 1px solid #efefef;
      margin: 15px 0;
      padding: 15px 0;

      .link-text, .cl-t {
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 5px;
      }
    }
  }

  /********************************************************
  BUTTON
  /********************************************************/

  .dark-btn {
    background-color: #343C4B;
    color: $white-color;
    box-shadow: 0px 10px 20px rgba(14,14,14,0.1);
    &:hover {
      color: $white-color;
      opacity: 0.8;
    }
  }
  .white-btn {
    background-color: #fff;
    color: #212529;
    &:hover {
      background: #f8f8f8;
      opacity: 0.8;
    }
  }
  .shadow-btn {
    box-shadow: 0px 10px 20px rgba(255,142,90,0.3);
  }
  .gray-btn {
    background-color: rgba(147,147,147,0.41);
    color: #939393;
    &:hover {
      color: #939393;
      opacity: 0.8;
    }
  }

  .no-cards {
    padding: 10px;
    margin: 50px 0;
    color: #fff;
    background: #ff974a;
  }


  .card-detail-modal{
    .card-tab {
      margin: 50px 0 20px 0;
      .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: #222222;
        background-color: #ffffff;
        border-bottom: 3px solid #FF8976;
        border-radius: 0;
      }
      .nav-link{
        color: #222222;
        &:hover{
          color: #222222;
        }
      }
      .nav-pills{
        border-bottom: 1px solid #efefef;
      }
      .cr-title{
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 600 !important;
        text-align: left;
      }
      .cfi-item{
        margin-top: 0 !important;
        margin-bottom: 5px;
        font-weight: 500 !important;
        img{
          margin-right: 15px !important;
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .carousel-root {
    .card-item-container {
      padding: 0px 5px 20px 5px;
    }
  }
  
  .top-carousel-root {
    position: relative;
    transition: all 0.1s cubic-bezier(0.16, 1, 0.3, 1);
    .tab-card-data-container {
      padding: 0px 5px 20px 5px;
      position: unset;
    }
    .tab-card-data {
      max-height: 3000px;
    }
    .react-multiple-carousel__arrow {
    }
  }

  /********************************************************/
  /* Max-Width 1400PX     *********************************/
  /********************************************************/
  @media (max-width: 1400px) {
    .data-graph .graph-info {
      width: 100%;

    }
    .dg-area .dg-dates{
      width: 100%;
    }

  }



  /********************************************************/
  /* Max-Width 1200PX     *********************************/
  /********************************************************/
  @media (max-width: 1200px) {
    .table-card-data .table-card {
      width: 150%;
    }
    .view-card .collapse {
      overflow: hidden;
    }
    .carousel-root {
      max-width: calc(100vw - 140px);
    }
  }

  /********************************************************/
  /* Max-Width 1024PX     *********************************/
  /********************************************************/
  @media (max-width:1024px) {
    .first-step {
      &:before {
        display: none;
      }
    }
    .w-half {
      width: auto;
    }
    .profile-sidebar #content {
      padding: 20px 0 0 5px;
    }
    .graph-left {
      flex: 2;
    }
    .see-more .card-dtl {
      .cr-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .card-dtl-item {
        width: 100%;
        span {
          margin-left: 10px;
          margin-right: 0;
          margin-bottom: 12px;
          line-height: 16px;
        }
      }
    }
    .table-card-data {
      display: none;
    }
    .mobile-filter {
      display: block;
      margin-top: 20px;
      width: 95%;
    }
    .table-card .header-card {
      flex-direction: column;
      .right-filter-label {
        margin-left: inherit;
        margin-top: 20px;
        margin-bottom: 0;
      }
      .left-filter-label {
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 20px;
      }
    }
    .view-card .table-responsive {
    }
    .mob-card-table {
      display: block;
      border: 1px solid #efefef;
      border-radius: 25px;
      padding: 20px;
      position: relative;
      margin: 10px;
      .align-set {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
      }
      .mc-detail {
        margin-bottom: 20px;
      }
      .luci-btn {
        font-size: 14px;
        padding: 3px 10px;
        min-width: 200px;
        line-height: 10px;
        font-weight: 500 !important;
        margin-bottom: 15px;
      }
      .cat-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
      .cat-area {
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        text-align: left;
        padding: 10px 0;
        .feature-tab {
          padding: 3px 0;
        }
      }
      .card-features {
        width: 100%;
        text-align: left;
        .card-left {
          width: 100%;
        }
      }
      img.responsive-card {
        max-width: 175px;
        min-width: 175px;
      }
      img.black-arrow {
        top: 350px;
        left: -21px;
        right: auto;
      }
      img.black-arrow.right {
        top: 340px;
        right: -22px;
        left: auto;
      }
      .card-id{
        .card-name {
          margin: 10px 0;
        }
      }
      .card-left {
        border-left: none;
        padding-left: 0;
      }
    }
    .table-card {
      .header-card {
        .right-filter, .left-filter {
          width: 100%;
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
    }
    .see-more .card-dtl{
      display: block;
    }
    .card-detail-mobile-section {
      padding: 0;
      background: #FFF9F3;
      .card-features {

        .total-rewards {
          border-top: none;
          margin: 0;
          background: white;
          border-radius: 25px 25px 0 0;
          padding: 15px 20px;
          &.no-top-padding {
            padding-top: 0 !important;
          }
          .is-top-padding {
            //flex-direction: column;
            padding-top: 13px;
          }
          .value {
            color: #FDA586;
          }
        }
        .mob-card-left {
          padding: 0 15px;
          margin-top: 10px;
          .hidden-visibility {
            visibility: hidden;
          }
        }
      }
    }
  }

  /********************************************************/
  /* Max-Width 991PX     *********************************/
  /********************************************************/
  @media (max-width:991px) {
    .second-step {
      .luci-title {
        width: 100%;
      }
    }
    .data-graph-border {
      border: 1px solid #DDE1ED;
      box-sizing: border-box;
      border-radius: 10px;
    }
    .data-graph .graph-info {
      flex-direction: column;
      width: 100%;
      .graph-right {
        flex: none;
        margin-right: 0;
        padding: 0 5%;
        width: 100%;
        margin-top: 0;
      }
      .graph-left {
        flex: none;
        margin: 12px 0px 25px 0px;
        width: 100%;
      }
      .gr-item.title {
        right: 0;
      }
    }
    .tab-card-data {
      padding: 10px;
      margin-bottom: 15px;
      .top-pick {
        margin: 0 0 10px -10.5px;
      }
      .card-first {
        flex-direction: column;
      }
      .t-card-img {
        margin-bottom: 10px;
        text-align: center;
        margin-top: 0;
        img {
          width: 97%;
        }
      }
      .t-card-name {
        margin: 10px 0;
        text-align: center;
        h4 {
          font-size: 18px;
          font-weight: 600;
        }
        display: none;
      }
      .t-card-action {
        text-align: center;
      }
      .card-points {
        flex-direction: column;
        align-items: start;
        height: unset;
        & > div {
          padding: 7px 0 7px 25px;
        }
        .card-r.ml-5 {
          margin-left: 0 !important;
        }
      }
      .card-reward {
        width: 100%;
        border-left: none;
      }
      .card-features {
        .card-f-area {
          flex-direction: column;
          .card-right {
            padding-left: 10px;
            width: 100%;
            border-left: none;
            flex-direction: column;
          }
          .card-left {
            width: 100%;
            padding-left: 10px;
            padding-top: 10px;
            margin-top: 20px;
            border-left: none;
            border-top: 1px solid rgba(0, 0, 0, 0.06);
          }
        }
      }
      .web-hide {
        margin-left: 10px;
      }
      .data-graph {
        .dg-title {
          text-align: center;
        }
        .dg-dates {
          flex-direction: column-reverse;
          width: 100%;
          .dg-date {
            margin-top: 0;
            margin-left: 0;
            font-size: 15px;
            font-weight: normal;
          }
          .btn-tab .dark-link {
            padding: 10px 8px;
            font-size: 12px;
          }
          .btn-tab .light-link {
            padding: 10px 10px;
            font-size: 12px;
          }
        }
      }
    }
    .pick-text {
      text-align: left;
    }
    .card-filter {
      flex-direction: column;
      align-items: flex-end;
      .feature-filter {
        margin-top: 10px;
        margin-left: 0;
        margin-right: 5px;
      }
      .filter {
        display: contents;
      }
      .free-text {
        //max-width: 515px;
        margin-bottom: 10px;
      }
    }
    img.black-arrow {
      top: 216px;
      left: -13px;
      width: auto;
      display: none;
      &.right {
        top: 192px;
        right: -12px;
        width: auto;
      }
    }
    .mob-card-table {
      img.black-arrow {
        top: 285px;
        left: -21px;
        right: auto;
        display: block;
      }
      img.black-arrow.right {
        top: 264px;
        right: -22px;
        left: auto;
      }
    }

    .visible-xs {
      img.black-arrow {
        top: 155px;
        left: -36px;
        width: 63px;
        display: block;
        &.right {
          top: 143px;
          right: -35px;
          width: 63px;
          display: block;
          left: auto;
        }
      }
    }
    .tab-card .tab-item-title {
      display: none;
    }
    .luci-card .luci-text {
      font-size: 13px;
      width: 100%;
    }
    .dg-area .dg-dates{
      width: 100%;
      .dg-date {
        width: 100%;
      }
    }

    .card-reward-xs {
      display: none !important;
    }
    .carousel-root {
      max-width: calc(100vw - 190px);
    }
  }

  @media (min-width: 991px) {
    .card-reward-sm {
      display: none !important;
    }
    .data-graph-border {
      border: 1px solid #DDE1ED;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 20px;
    }
  }

  .bg-dashboard{
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    position: sticky;
  }

  /********************************************************/
  /* Min-Width 767PX     *********************************/
  /********************************************************/
  @media (min-width: 767px) {
    .web-hide{
      display: none;
    }
    .graph-pl-40 {
      padding-left: 40px;
    }
    .graph-pr-40 {
      padding-right: 40px;
    }
    .mob-card-left {
      column-gap: 20px;
    }
    #content.content-with-sidebar {
      width: calc(100% - 110px) !important;
    }
  }

  /********************************************************/
  /* Max-Width 767PX     *********************************/
  /********************************************************/
  @media (max-width:767px) {
    header {
      .navbar-light .user-name .short-name {
        width: 34px;
        height: 34px;
        padding-top: 7px;
        font-size: 13px;
      }
      .bg-dashboard {
        .btn-signup {
          right: 70px;
          top: 0;
        }
        .navbar-light {
          padding: 10px 0 10px 10px !important;
        }
      }
    }
    .profile-sidebar {
      #sidebar {
        min-width: 290px;
        max-width: 290px;
        position: absolute;
        left: 0;
        width: 290px;
        //z-index: 9;
        z-index: 12;
        height: 100%;
      }
      #sidebar.active {
        min-width: 60px;
        max-width: 60px;
        position: relative;
        width: 60px;
        margin-left: -60px;
        a#sidebarCollapse {
          right: 8px;
        }
      }
      .sidebar-header {
        left: 50px;
        top: -8px;
        height: 0;
        img {
          border: none;
        }
      }
    }
    .r-btn .luci-btn {
      width: 250px;
    }
    .view-sm {
      display: grid;
      justify-content: center;
    }
    #sidebar {
      position: fixed !important;
    }
    #sidebar.active .sidebar-nav .nav-item a {
      margin-left: 7px;
    }
    .modal-popover {
      .tooltip-luci {
        top: 0;
        left: 21px;
        right: 15px;
        width: 80%;
        min-width: auto;
        i {
          top: -6px;
          left: 18px;
        }
      }
    }
    .categories-list .c-list .category-input {
      width: 80px;
    }
    .bank-area {
      width: 100%;
      margin-bottom: 30px;
    }
    .wrapper.profile-sidebar {
      margin-top: 65px;
      height: auto;
    }
    .bank-area {
      .card-header .btn-link {
        padding: 20px 15px 20px 45px;
        .card-enable {
          display: none;
        }
        > .card-id {
          width: 50px;
        }
        .card-name {
          margin-left: 5px;
        }
      }
      .card-sub-data {
        margin-left: 0;
        > img {
          display: none;
        }
      }
      .card-sub-data {
        .toggle {
          right: 0;
        }
        .card-enable {
          margin-right: 55px;
        }
      }
    }
    .padd-xs {
      padding: 0 !important;
    }
    .card-popover {
      position: absolute;
      right: 95px;
      top: 40px;
      .tooltip-luci {
        visibility: hidden;
        opacity: 0;
      }
      &:hover {
        .tooltip-luci {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .modal-popover {
      .single-modal-item {
        left: auto;
        position: absolute;
        width: 185px;
        visibility: hidden;
        opacity: 0;
      }
      &:hover {
        visibility: visible;
        opacity: 1;
      }
      .tasker-popover-arrow {
        display: none;
      }
    }
    .plaid-btn{
      width: 250px;
    }
    .gr-item img:not(.gray-help) {
      margin-right: 7px;
    }
    .feature-tab {
      img{
        margin-right: 5px;
        margin-top: -3px;
      }
    }
    .w-half{
      .modal-popover{
        .help-icon {
          display: inline-block;
        }
      }
    }
    .help-icon{
      //margin-top: 7px;
    }
    .bank-area .card-popover .tooltip-luci{
      left: -80px;
      right: auto;
      min-width: auto;
      width: 170px;
      display: flex;
      top: 16px;
    }
    .second-step {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .profile-sidebar #content {
      padding-left: 0;
      width: 100%;
    }
    #content {
      width: 100%;
      overflow-x: hidden;
    }
    .visible-xs img.black-arrow {
      left: -22px;
    }
    .visible-xs img.black-arrow.right {
      right: -22px;
    }
    .luci-sub-title {
      padding-left: 15px;
    }
    .mob-card-table {
      img.black-arrow.right {
        width: 65px;
        top: 269px;
        right: -25px;
      }
      img.black-arrow {
        width: 65px;
        left: -24px;
      }
    }
    //.card-features .card-left .cl-item {
    //  width: 100%
    //}
    .mc-detail .card-left .cl-item {
    }
    .n-p-mobile {
      padding: 0 !important;
    }
    .card-features {
      .card-f-area {
        flex-direction: column;
        .card-right {
          padding-left: 10px;
          width: 100%;
          flex-direction: column;
        }
        .card-left {
          width: 100%;
          border-left: none;
          border-top: 1px solid #dfdfdf;
          padding-top: 10px;
          margin-top: 15px;
          padding-left: 10px;
          //.cards {
          //  flex-direction: column;
          //}
        }
        .popup-card-left {
          padding: 0;
          border-top: none;
          margin-top: 0;
          width: 100% !important;
        }
      }
    }
    .carousel-root {
      max-width: unset;
    }
    .mobile-hide {
      display: none !important;
    }
  }
}

// why is global ant-popover styling done here? not modular
/* 
.ant-popover-content {
  padding: 0;
  .item-gap {
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style-type: none;
        padding: 0;
        a {
          padding: 8px 5px 8px 15px;
          cursor: pointer;
          display: block;
          color: #222222;
          text-decoration: none;
          background: #ffffff;
          &:hover {
            background: $link-text;
          }
          &.active {
            background: $link-text;
          }
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    .ant-checkbox {
      margin-top: 5px;
      height: 15px;
    }
  }
}
*/

.dashboard-wrapper .modal-body .card-features {
  .card-left {
    width: 48%;
    display: block;
    flex-wrap: wrap;
  }
  .card-f-area .card-right{
    padding-left: 30px;
    .cfi-item{
      width: 100%;
    }
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }
}

.modal-content{
  border-radius: 10px !important;
  overflow: hidden;
}

.unlink-account-modal {
  max-width: 380px;
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-body {
      .warning-image {
        width: 40px;
      }
      .p-card-title {
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: #222222;
      }
      .unlink-desc {
        margin-top: 20px;
        font-size: initial;
      }
      .luci-btn {
        height: 40px;
        min-width: 150px;
        padding: 10px 0;
      }
    }
    .modal-footer {
      padding: 0.75rem 0;
      .share-plaid-btn {
        height: 50px;
        min-width: 200px;
      }
    }
  }
}

.share-using-plaid-modal {
  max-width: unset;
  .plaid-body {
    display: flex;
    .plaid-description {
      width: 50%;
      border-right: 1px solid #DDE1ED;
      padding-right: 20px;
    }
    .example-slide {
      width: 50%;
      padding: 20px;
      .slide-card-title {
        text-align: center;
        min-height: 50px;
      }
      .image-container {
        text-align: center;
        img {
          width: 300px;
          height: 360px;
        }
      }
    }
    .carousel-control-prev-icon {
      background-image: url("../../assets/images/dashboard/arrow-black.svg");
      width: 50px;
      height: 50px;
    }
    .carousel-control-next-icon {
      background-image: url("../../assets/images/dashboard/arrow-black.svg");
      transform: rotate(180deg);
      width: 50px;
      height: 50px;
    }
    .carousel-control-next {
      bottom: unset;
      top: 200px;
    }
    .carousel-control-prev {
      bottom: unset;
      top: 210px;
    }
  }
  @media (max-width: 991px) {
    width: 70% !important;
    .plaid-body {
      flex-direction: column;
      .plaid-description {
        width: unset;
        border-right: none;
        padding-right: 0;
      }
      .example-slide {
        width: unset;
        padding: 20px 0
      }
    }
  }
  @media (max-width: 567px) {
    width: 95% !important;
    .ant-modal-body {
      padding: 10px
    }
  }
}

.ant-tooltip-open{
  img{
    &:hover{
      cursor: pointer;
    }
  }
}

.help-icon:hover,.blue-txt img:hover,.first-c img:hover,.w-nowarp img:hover,.card-fee img:hover{
  cursor: pointer;
}

.card-setting-modal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      border-bottom: none;
      border-radius: 10px;
      .ant-modal-title {
        font-size: 20px;
      }
    }
    .ant-modal-body {
      padding-top: 10px;
      .date-setting {
        .range-title {
          border: 1px solid #dfdfdf;
          padding: 10px 30px;
          border-radius: 5px;
          color: #FDA586;
          text-align: center;
          margin-top: 20px;
          margin-right: 20px;
          cursor: pointer;
          &.selected {
            border: 1px solid #ffa255;
            background: #fff2ed;
          }
        }
        .date-change-title {
          text-align: right;
          color: $link-text;
          margin-top: 5px;
          margin-right: 20px;
          cursor: pointer;
          font-size: 12px;
        }
      }
      .card-setting {
        border-left: 1px solid #dfdfdf;
        padding-left: 20px;

        @media (max-width: 767px) {
          border-left: none;
          padding-left: 15px;
          margin-top: 20px;
        }
      }
      .gray-btn {
        background-color: rgba(147,147,147,0.41);
        color: #939393;
        &:hover {
          color: #939393;
          opacity: 0.8;
        }
      }
    }
  }
}

.find-cards-modal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      .p-card-title {
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        color: #222222;
      }
      .subline-text {
        align-items: baseline;
        p {
          margin-left: 20px;
          font-size: 20px;
        }
      }
      .categories-list {
        margin: 15px 0;
        .c-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 15px;
          .c-name {
            display: block;
            font-size: 18px;
            color: #222222;
            margin-left: 20px;
          }
          .category-input {
            color: #222222;
            font-size: 18px;
            border: 1px solid #343C4B;
            background: #FFFFFF;
            border-radius: 7px;
            width: 130px;
            height: 37px;
            padding: 2px 5px;
            text-align: right;
            margin-left: auto;
          }
          img {
            width: 35px;
            padding: 5px;
            border-radius: 5px;
          }
        }
        .c-total {
          font-weight: 600;
          font-size: 23px;
          line-height: 34px;
          margin-left: auto;
        }
      }
      .gray-btn {
        background-color: rgba(147,147,147,0.41);
        color: #939393;
        &:hover {
          color: #939393;
          opacity: 0.8;
        }
      }
      .rewards-calculation {
        b {
          color: black;
          font-size: 17px;
        }
        .ant-checkbox-wrapper {
          font-size: 16px;
          color: $black-color;
        }
      }
    }
  }
}

.ant-modal-content-only {
  width: 450px;
  max-width: 100%;
  .p-card-title {
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    color: #222222;
  }
  .subline-text {
    align-items: baseline;
    p {
      margin-left: 20px;
      font-size: 20px;
    }
  }
  .categories-list {
    margin: 15px 0;
    .c-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
      .c-name {
        display: block;
        font-size: 18px;
        color: #222222;
        margin-left: 20px;
      }
      .category-input {
        color: #222222;
        font-size: 18px;
        border: 1px solid #343C4B;
        background: #FFFFFF;
        border-radius: 7px;
        width: 130px;
        height: 37px;
        padding: 2px 5px;
        text-align: right;
        margin-left: auto;
      }
      img {
        width: 35px;
        padding: 5px;
        border-radius: 5px;
      }
    }
    .c-total {
      font-weight: 600;
      font-size: 23px;
      line-height: 34px;
      margin-left: auto;
    }
  }
  .gray-btn {
    background-color: rgba(147,147,147,0.41);
    color: #939393;
    &:hover {
      color: #939393;
      opacity: 0.8;
    }
  }
  .rewards-calculation {
    b {
      color: black;
      font-size: 17px;
    }
    .ant-checkbox-wrapper {
      font-size: 16px;
      color: $black-color;
    }
  }
}

.spending-money-modal {
  @media (min-width: 767px) {
    width: 70% !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 50px;
      .category-section {
        padding: 50px;
        .category-row {
          border: 1px solid #DDE1ED;
          border-radius: 20px;
          padding: 50px;

          .section-1 {
            border-bottom: 1px solid #DDE1ED;
            border-right: 1px solid #DDE1ED;
          }
          .section-2 {
            border-bottom: 1px solid #DDE1ED;
          }
          .section-3 {
            border-right: 1px solid #DDE1ED;
          }
        }
      }
      @media (max-width: 767px) {
        padding: 20px;

        .category-section {
          padding: 20px;

          .category-row {
            padding: 10px 20px;

            .section-1 {
              border-right: none;
            }

            .section-2 {
              border-bottom: 1px solid #DDE1ED;
            }

            .section-3 {
              border-right: none;
              border-bottom: 1px solid #DDE1ED;
            }
          }
        }

      }
    }
  }
}

.card-detail-modal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      padding: 0;
      .modal-title{
        font-size: 16px;
      }
      .modal-header{
        background: #DDE1ED;
      }
      .card-first {
        display: block;
      }
      .t-card-name {
        margin: 0;
        h4 {
          font-size: 26px;
          font-weight: 600;
        }
        .t-card-info {
          max-width: 790px;
        }
      }
      .card-image {
        max-width: 200px;
        min-width: 200px;
      }
      .card-f {
        display: block;
        justify-content: space-around;
        padding: 0 0 0 0;
        width: 100%;
      }
      .cf-item {
        text-align: left;
        display: flex;
        width: 100%;
        padding: 7px 0;
        margin-left: 30px;
        max-width: 300px;
        &:last-child{
          border-bottom: none;
        }
      }
      .cf-title {
        display: block;
        font-size: 16px;
        line-height: 24px;
        min-width: 125px;
      }
      .cf-value{
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 21px;
        white-space: nowrap;
      }
      img.gray-help {
        height: 20px;
        opacity: 0.3;
        position: relative;
        bottom: 0.5px;
      }
      .card-tab {
        margin: 40px 0 20px 0;
        .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
          color: #222222;
          background-color: #ffffff;
          border-bottom: 3px solid #FF8976;
          border-radius: 0;
        }
        .nav-link{
          color: #222222;
          &:hover{
            color: #222222;
          }
        }
        .nav-pills{
          border-bottom: 1px solid #efefef;
        }
        .cr-title{
          font-size: 18px;
          margin-bottom: 10px;
          font-weight: 600 !important;
          text-align: left;
        }
        .cfi-item{
          margin-top: 0 !important;
          margin-bottom: 5px;
          font-weight: 500 !important;
          img{
            margin-right: 15px !important;
            width: 18px;
            height: 18px;
          }
        }
      }
      .card-features {
        .card-f-area {
          justify-content: space-between;
          display: flex;
          .card-right {
            margin-right: auto;
            width: 50%;
            .cf-icons {
              display: flex;
              flex-wrap: wrap;
            }
            .cfi-item {
              display: flex;
              align-items: center;
              justify-content: start;
              width: 50%;
              margin-top: 15px;
              font-weight: 600;
              img {
                margin-right: 15px;
              }
            }
          }

          .card-right {
            padding-left: 30px;
            .cfi-item {
              width: 100%;
            }
            @media (max-width: 991px) {
              padding-left: 0;
            }
          }

        }
        .card-left {
          width: 50%;
          border-left: 1px solid #dfdfdf;
          padding-left: 50px;
          .cards {
            display: flex;
            flex-wrap: wrap;
          }
          .cl-item {
            margin-bottom: 20px;
            width: 50%;
            .cl-t {
              font-weight: 600;
              font-size: 16px;
              display: block;
            }
          }
        }
        .popup-card-left {
          width: 50% !important;
          flex-wrap: wrap !important;
          border-left: none;
          padding-left: 0;
        }
        .popup-card-right {
          border-left: 1px solid #dfdfdf;
        }
        .mob-card-left {
          display: flex;
          flex-wrap: wrap;
        }
        .cr-title {
          font-size: 18px;
          font-weight: 600;
        }
      }

      @media (max-widthď991px) {
        .card-first {
          flex-direction: column;
        }
        .t-card-name {
          margin: 15px 0;
        }
        .card-f {
          margin-top: 15px;
          flex-wrap: wrap;
          justify-content: center;
          display: flex;
        }
        .cf-item {
          width: 50%;
          margin-bottom: 10px;
        }
        .card-features {
          .card-f-area {
            flex-direction: column;
          }

          .card-right {
            border-left: none;
            padding-left: 0;
            margin-top: 25px;
          }
        }
      }

      .see-more {
        text-align: center;
        .card-dtl {
          display: flex;
          .card-dtl-item {
            display: flex;
            align-items: start;
            text-align: left;
            justify-content: flex-start;
            width: 100%;
            img{
              margin-top: 4px;
            }
          }
        }
      }

      @media (max-width:1024px) {
        .see-more .card-dtl {
          .card-dtl-item {
            width: 100%;

            p {
              margin-left: 15px;
              margin-right: 0;
            }
          }
        }
      }

      @media (min-width: 1024px) {
        .see-more .card-dtl {
          .card-dtl-l {
            width: 50%;
            border-right: 1px solid #dfdfdf;
            p {
              margin-left: 15px;
              margin-right: 0;
            }
          }

          .card-dtl-r {
            width: 50%;
            padding-left: 2%;
            p {
              margin-left: 15px;
              margin-right: 0;
            }
          }
        }
      }


      @media (max-width: 1024px) {
        .see-more .card-dtl {
          //display: flex;
          flex-direction: column;
          .card-dtl-l {
            width: 100%;
            border-right: none;
          }

          .card-dtl-r {
            width: 100%;
            padding-left: 0;
          }
        }

        .card-modal-line{
          flex-direction: column;
          align-items: center;

          .cf-item{
            width: 100% !important;
            margin-left: 0 !important;
            margin-top: 10px;
            display: flex;
            align-items: center;
          }
        }
      }

      @media (max-width:767px) {
        .card-f-area {
          flex-direction: column;

          .card-right {
            padding-left: 10px;
            width: 100% !important;
            flex-direction: column;
          }

          .card-left {
            width: 100%;
            border-left: none;
            border-top: 1px solid #dfdfdf;
            padding-top: 10px;
            margin-top: 15px;
            padding-left: 10px;
            //.cards {
            //  flex-direction: column;
            //}
          }

          .popup-card-left {
            padding: 0;
            border-top: none;
            margin-top: 0;
            width: 100% !important;
          }
        }
      }

    }
  }
}

.unset-z-index {
  z-index: unset !important;
}

.z-index-11 {
  z-index: 11 !important;
}

.z-index-111 {
  z-index: 111 !important;
}

.top-category {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.word-break-all {
  word-break: break-all;
}

.card-modal-line{
  display: flex;
  justify-content: space-between;
  .t-card-img {
    display: flex;
    flex-direction: column;
    button {
      width: 98%;
      margin-top: 15px;
      height: 35px;
      border: none;
      background: #ffa056;
      color: white;
      font-weight: bold;
      font-family: inherit;
      max-width: 200px;
      min-width: 200px;
      padding: 0;
    }
  }
}

.card-skeleton {
  margin: 0 0 400px 0;
}

@media (min-width: 1024px) {
  .see-more .card-dtl {
    .card-dtl-l {
      border-right: 1px solid #dfdfdf;
    }

    .card-dtl-r {
    }
  }
}


@media (max-width: 1024px) {
  .see-more .card-dtl {
    display: flex;
    .card-dtl-l {
      width: 100%;
      border-right: none;
    }

    .card-dtl-r {
      width: 100%;
      padding-left: 0;
    }
  }

  .card-modal-line{
    flex-direction: column;

    .cf-item{
      width: 100% !important;
      margin-left: 0 !important;
      margin-top: 10px;
    }
  }
}

@media (min-width: 991px) {
  .about-pr-50 {
    padding-right: 50px;
  }
}

@media (max-width: 576px) {
  .bank-area {
    .card-sub-data {
      .toggle {
        margin-top: 10px;
      }
    }
  }
  .dashboard-wrapper .tab-card-data .data-graph .dg-title.reward-category span {
    display: flex;
    justify-content: center;
  }
  .data-graph {
    .graph-info {
      .graph-right {
        .gr-name {
          .cat-name {
            min-width: 30px !important;
          }
        }
      }
    }
  }
  .dashboard-wrapper .data-graph .graph-info .graph-right .gr-item img:not(.gray-help) {
    margin-right: 5px;
  }
  .dashboard-wrapper .data-graph .graph-info .graph-right .gr-spending {
    width: 110px;
  }
  .dashboard-wrapper .data-graph .graph-info .gr-item {
    max-height: 40px;
  }
  .advertiser-disclosure-modal {
    width: 95% !important;
  }
}

.child-feature:nth-child(1n) {
  padding-right: 5px;
}

.child-feature:nth-child(2n) {
  padding-left: 5px;
}

.hide-triangle-icon .ant-tooltip-content .ant-tooltip-arrow {
  display: none;
}

.method-tab {
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  .clickable-option {
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    background: linear-gradient(to right, #ccc, #ddd, #ddd);
    cursor: pointer;
    margin: 4px;
  }
  .selected {
    background: linear-gradient(to right, #FF8C5A, #FF9458, #FFA655);
    color: $white-color;
  }
}
